import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { Provider } from "react-redux";
import store from "./store";
import { LoadingOverlay } from "./components/LoadingOverlay";
import { WebMap } from "./components/WebMap";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
      <WebMap />
      <LoadingOverlay />
    </React.StrictMode>
    ,
  </Provider>,
);
